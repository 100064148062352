import { useMediaQuery } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
// material-ui
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Skeleton,
    TextField,
    Typography,
    useTheme
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
// third party
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import Send from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/system';
import { useQueries, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubCard from 'ui-component/cards/SubCard';
import * as Yup from 'yup';
import { useEffect } from 'react';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const AddloadForm = () => {
    const theme = useTheme();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const [loadingFile, setLoadingFile] = useState(false);
    const [parsedData, setParsedData] = useState();
    const [loadingCity, setLoadingCity] = useState(false);
    const [addError, setAddError] = useState();
    const [rateConMsg, setRateConMsg] = useState('');
    const navigate = useNavigate();
    const [customerObj, setCustomerObj] = useState({});
    const cid = [
        'dispatcher_admin',
        'accountant',
        'dispatcher',
        'auditor',
        'self_dispatch',
        'carrier',
        'mechanic_admin',
        'hr',
        'super_dispatcher_admin'
    ].includes(loginInfoFromStorage.user_type)
        ? loginInfoFromStorage.company_id
        : 0;

    // keep track of customer input
    const [customerOpen, setCustomerOpen] = useState(false);
    const [customerOptions, setCustomerOptions] = useState([]);

    // keep track of driver input
    const [driverOpen, setDriverOpen] = useState(false);
    const [driverOptions, setDriverOptions] = useState([]);

    // multiple file upload handler
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleDrop = async (e) => {
        e.preventDefault();
        const incomingFiles = Array.from(e.dataTransfer.files);
        setSelectedFiles(incomingFiles);
    };

    const handleFileInputChange = async (e) => {
        const incomingFiles = Array.from(e.target.files);
        setSelectedFiles(incomingFiles);
    };

    // to keep track of automatic email sends
    const [emailList, setEmailList] = useState('');
    const [emailFlag, setEmailFlag] = useState(false);

    const FaqSchema = Yup.object().shape({
        customer: Yup.object()
            .strict(true)
            .typeError("Customer can't be blank")
            .required('Customer is required')
            .test('is-empty', "Customer can't be blank", (value) => {
                return Object.keys(value).length > 0;
            }),
        driver: Yup.object()
            .strict(true)
            .typeError("Driver can't be blank")
            .required('Driver is required')
            .test('is-empty', "Driver can't be blank", (value) => {
                return Object.keys(value).length > 0;
            }),
        weight: Yup.number().typeError('This should be a valid number'),
        revenue: Yup.string()
            .matches(/^\d{1,9}(?:\.\d+)?$/, { message: 'This should be a valid number' })
            .max(9, 'Revenue must be less than 9 digits'),
        millage: Yup.number().typeError('This should be a valid number'),
        load: Yup.string().required('This is a required field'),

        pickup: Yup.array().of(
            Yup.object().shape({
                street: Yup.string().required('This field is required'),
                date: Yup.string().required('Date is required'),
                time: Yup.date().required('Time is required').typeError('Invalid time'),
                city: Yup.number().required('This field is required'),
                state: Yup.number().required('This field is required'),
                zipcode: Yup.string().required('This field is required')
            })
        ),
        delivery: Yup.array().of(
            Yup.object().shape({
                street: Yup.string().required('This field is required'),
                date: Yup.string().required('Date is required'),
                time: Yup.date().required('Time is required').typeError('Invalid time'),
                city: Yup.number().required('This field is required'),
                state: Yup.number().required('This field is required'),
                zipcode: Yup.string().required('This field is required')
            })
        )
    });
    const empPic = {
        code: '',
        customer: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        date: '',
        time: '',
        type: 'pickup'
    };
    const empDel = {
        code: '',
        customer: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        date: '',
        time: '',
        type: 'delivery'
    };
    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            customer: customerObj,
            driver: {},
            custName: customerObj?.customer_name || '',
            truck: '',

            pickup: parsedData
                ? parsedData.pickups
                : [
                      {
                          code: '',
                          customer: '',
                          street: '',
                          city: '',
                          city_list: [],
                          state: '',
                          zipcode: '',
                          date: '',
                          time: '',
                          type: 'pickup'
                      }
                  ],
            delivery: parsedData
                ? parsedData.delivery
                : [
                      {
                          code: '',
                          customer: '',
                          street: '',
                          city_list: [],
                          city: '',
                          state: '',
                          zipcode: '',
                          date: '',
                          time: '',
                          type: 'delivery'
                      }
                  ],
            weight: parsedData ? parsedData.cust_info.weight : '',
            revenue: parsedData ? parsedData.cust_info.revenue : '',
            millage: parsedData ? parsedData.cust_info.millage : '',
            load: parsedData ? parsedData.cust_info.load : ''
        },
        validationSchema: FaqSchema,
        onSubmit: async () => {
            if (values.truck === '') {
                toast.error('Truck Number is Required!');
                return false;
            }

            for (let i = 1; i < values.pickup.length; i++) {
                for (let j = 0; j < i; j++) {
                    if (
                        values.pickup[i].date < values.pickup[j].date ||
                        (values.pickup[i].date.toString() === values.pickup[j].date.toString() && values.pickup[i].time < values.pickup[j].time)
                    ) {
                        toast.error('Pickup dates and times must be in increasing order from start to end');
                        return false;
                    }
                }
            }

            for (let i = 0; i < values.pickup.length; i++) {
                for (let j = 0; j < values.delivery.length; j++) {
                    if (
                        values.delivery[j].date < values.pickup[i].date ||
                        (values.delivery[j].date.toString() === values.pickup[i].date.toString() && values.delivery[j].time < values.pickup[i].time)
                    ) {
                        toast.error('Delivery date should be after pickup date');
                        return false;
                    }
                }
            }

            const loadData = {
                company_id: loginInfoFromStorage.company_id,
                customer_id: values.customer.dsp_customer_id,
                driver_id: values.driver.driver_id,
                truck_id: values.driver.truck_id,
                is_active: true,
                is_edit: false,
                load: values.load,
                mileage: values.millage,
                revenue: values.revenue,
                weight: values.weight,
                pickups: values.pickup.map(({ customer, street, city, state, zipcode, date, time }) => ({
                    company_name: customer,
                    address: street,
                    city_id: city,
                    state_id: state,
                    zip_code: zipcode,
                    country_id: 233,
                    date: format(new Date(date), 'yyyy-MM-dd'),
                    time: format(new Date(time), 'HH:mm'),
                    load_type: 'pickup',
                    driver_id: values.driver.driver_id,
                    is_edit: false
                })),
                delivery: values.delivery.map(({ customer, street, city, state, zipcode, date, time }) => ({
                    company_name: customer,
                    address: street,
                    city_id: city,
                    state_id: state,
                    zip_code: zipcode,
                    country_id: 233,
                    date: format(new Date(date), 'yyyy-MM-dd'),
                    time: format(new Date(time), 'HH:mm'),
                    load_type: 'delivery',
                    driver_id: values.driver.driver_id,
                    is_edit: false
                }))
            };

            const formData = new FormData();
            formData.append('load_data', JSON.stringify(loadData));
            selectedFiles.forEach((file) => {
                formData.append('pdf_files', file);
            });
            // it's for automatic email part
            if (emailList?.length >= 6) {
                formData.append('email_status', JSON.stringify({ email_list: emailList, email_flag: emailFlag }));
            }
            try {
                const { data } = await axios.post(`/v1/api/dispatch/load/add/`, formData, config);
                navigate('/dispatch-center');
                toast.success('Load Added successfully');
            } catch (error) {
                if (process.env.NODE_ENV == 'development') {
                    console.log(error.response.data.error);
                }

                setAddError(error.response.data);
                if (error.response.data.street) {
                    toast.error('One of the given address was invalid');
                } else {
                    toast.error('Something Went Wrong!');
                }
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    console.log(values);

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const fileUploadHandler = async (e) => {
        toast.info("We're working on the pdf file to populate the data, Please wait", {
            theme: 'light'
        });

        e.preventDefault();
        setLoadingFile(true);
        setRateConMsg('');

        try {
            const formData = new FormData();
            selectedFiles.forEach((file) => {
                formData.append('pdf_files', file);
            });
            formData.append('dispatch_doc_name', 'merged.pdf');
            const { data } = await axios.post(`/v1/api/dispatch/load/doc`, formData, config);

            setParsedData(data.res);
            setRateConMsg('Rate Confirmation Uploaded');
            toast.success('Rate Confirmation Uploaded successfully');
        } catch (error) {
            if (error.response.data?.error) {
                toast.error(error.response.data.error);
            }
        } finally {
            setLoadingFile(false);
        }
    };

    const fetchCustomerList = async () => {
        const response = await axios.get('/v1/api/dispatch/customer/list', {
            params: { page: 1, page_size: 500 },
            config // Use params for cleaner query string
        });
        setCustomerOptions(response.data.results);
        return response.data.results; // Directly return customer list
    };

    const {
        data: customerList,
        isLoading: customerLoading,
        error: customerError
    } = useQuery({ queryKey: ['customerList'], queryFn: fetchCustomerList, staleTime: 5 * 60 * 1000 });

    if (customerError) {
        console.log(customerError);
    }

    const fetchDriverList = async () => {
        const response = await axios.get('/v1/api/users/company/drivers/slim', {
            params: {
                company_id: cid,
                is_active: true,
                is_main: true,
                page: 1,
                page_size: 500
            },
            config
        });
        setDriverOptions(response.data.results);
        return response.data.results;
    };

    const {
        data: driverList,
        isLoading: driverLoading,
        error: driverError
    } = useQuery({ queryKey: ['driverList'], queryFn: fetchDriverList, staleTime: 5 * 60 * 1000 });

    if (driverError) {
        console.log(driverError);
    }

    const fetchStateList = async () => {
        const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);
        return data;
    };

    const {
        data: stateList,
        isLoading: stateLoading,
        error: stateError
    } = useQuery({ queryKey: ['stateList'], queryFn: fetchStateList, staleTime: 5 * 60 * 1000 });

    if (stateError) {
        console.log(stateError);
    }

    const getCityList = async (state, cityList, id) => {
        setFieldValue(state, id);
        setLoadingCity(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);
            setLoadingCity(false);
            setFieldValue(cityList, data);
        } catch (error) {
            if (process.env.NODE_ENV == 'development') {
                console.log(error.response);
            }
        }
    };

    const fetchCityList = async (stateId) => {
        const { data } = await axios.get(`/v1/api/users/city/list/${stateId}/`, config);

        return data;
    };

    const queries = formik.values.pickup?.map((location) => {
        return {
            queryKey: ['cityList', location.state],
            queryFn: () => fetchCityList(location.state),
            staleTime: 5 * 60 * 1000,
            enabled: !!location.state, // Only enable the query if state has a value
            onSuccess: (data) => {
                console.log(data);
                // Do something on success, for example, update formik values
                formik.setValues((prevValues) => ({
                    ...prevValues
                }));
            }
        };
    });
    const queryResults = useQueries({
        queries: queries
    });

    const NumberOnlyHandler = (e) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-letter characters
        e.target.value = updatedValue;
    };

    function searchByCustomerName(searchString) {
        const newList = customerList.filter((obj) => obj.customer_name.toLowerCase().includes(searchString.toLowerCase()));
        setCustomerOptions(newList);
    }

    function searchByDriverName(searchString) {
        const newList = driverList.filter((obj) => obj.name.toLowerCase().includes(searchString.toLowerCase()));
        setDriverOptions(newList);
    }

    const validateEmails = (emails) => {
        const emailArray = emails?.split(',').map((email) => email.trim());
        const invalidEmails = emailArray?.filter((email) => !isValidEmail(email));
        if (invalidEmails?.length > 0) {
            return 'Invalid email(s): ' + invalidEmails.join(', ');
        }
        return undefined;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography
                                sx={{
                                    mt: '30px',
                                    fontSize: '32px',
                                    fontstyle: 'normal',
                                    fontFamily: 'Lexend ',
                                    fontWeight: '600',
                                    lineHeight: '40px',
                                    letterSpacing: '0px',
                                    textAlign: 'left',
                                    height: '40px',
                                    width: '255px',
                                    left: '0px',
                                    top: '0px',
                                    color: '#142953'
                                }}
                            >
                                New Load Form
                            </Typography>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" sx={{ mt: '30px' }} justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <LoadingButton
                                    loading={formik.isSubmitting}
                                    variant="contained"
                                    onClick={() => {
                                        formik.submitForm();
                                        if (Object.keys(errors).length > 0) {
                                            toast.error('Please fillup all the field first');
                                        }
                                    }}
                                    color="orange"
                                    sx={{ color: 'white' }}
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard
                        sx={{
                            mt: 5,
                            borderRadius: '8px',
                            mb: 2,
                            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)'
                        }}
                    >
                        <SubCard
                            sx={{
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                {/* Customer */}

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        sx={{
                                            mb: 2,
                                            width: '1045px',
                                            height: '30px',
                                            fontWeight: '500',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend',
                                            lineHeight: '30px'
                                        }}
                                    >
                                        Customer
                                    </Typography>

                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {customerLoading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    className="text-input"
                                                    id="customer"
                                                    name="customer"
                                                    options={customerOptions || customerList}
                                                    loading={customerLoading}
                                                    open={customerOpen}
                                                    onBlur={formik.handleBlur('customer')}
                                                    onOpen={() => {
                                                        setCustomerOpen(true);
                                                    }}
                                                    onClose={() => {
                                                        setCustomerOpen(false);
                                                    }}
                                                    getOptionLabel={(option) => (option.customer_name ? option.customer_name : '')}
                                                    onInputChange={(_event, newInputValue) => {
                                                        searchByCustomerName(newInputValue);
                                                    }}
                                                    onChange={(_event, newValue) => {
                                                        setCustomerObj(newValue);
                                                        if (newValue) {
                                                            formik.setFieldValue('custName', newValue.customer_name);
                                                            setEmailList(newValue.billing_email);
                                                        } else {
                                                            setFieldValue('custName', '');
                                                            setEmailList('');
                                                        }
                                                    }}
                                                    value={values.customer}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Customer Name"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {customerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                )
                                                            }}
                                                            error={Boolean(touched.customer && errors.customer)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText error={Boolean(touched.customer && errors.customer)}>
                                                    {touched.customer && errors.customer ? errors.customer : 'Type atleast 2 characters to populate the list'}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Email sent options start */}
                            <Grid container mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    sx={{ textWrap: 'balance', mr: '0' }}
                                    control={<Checkbox checked={emailFlag} onChange={(e) => setEmailFlag(e.target.checked)} />}
                                    label="Do you want to send BOL and status update automatically to the broker with this email?"
                                />
                                <TextField
                                    sx={{ mt: 1 }}
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    label="Enter Emails"
                                    value={emailList}
                                    onChange={(e) => setEmailList(e.target.value)}
                                    error={Boolean(emailList && validateEmails(emailList))}
                                    helperText={validateEmails(emailList) || 'Enter multiple emails separated by commas'}
                                />
                                <div style={{ color: 'orange' }}>You can add multiple email addresses by separating them with semicolons</div>
                            </Grid>
                            {/* Email sent options end */}
                        </SubCard>
                        <SubCard
                            sx={{
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={10} md={6} sm={6} xs={12}>
                                    <>
                                        <input
                                            type="file"
                                            onChange={handleFileInputChange}
                                            accept=".pdf"
                                            multiple
                                            style={{ display: 'none' }}
                                            id="file-input"
                                        />
                                        <label htmlFor="file-input">
                                            <Box
                                                sx={{
                                                    border: '2px dashed orange',
                                                    borderRadius: '5px',
                                                    padding: '20px',
                                                    // textAlign: 'center',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#f9f9f9'
                                                }}
                                                onDrop={handleDrop}
                                                onDragOver={(e) => e.preventDefault()}
                                            >
                                                <div>
                                                    <Typography
                                                        variant="h2"
                                                        sx={{
                                                            fontWeight: '500',
                                                            fontSize: '24px',
                                                            fontFamily: 'Lexend',
                                                            marginTop: '5px'
                                                        }}
                                                    >
                                                        {rateConMsg ? rateConMsg : 'Rate Confirmation Upload!'}
                                                    </Typography>
                                                </div>
                                                <br />
                                                <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                                                    <DownloadIcon />
                                                    <Typography variant="h6" gutterBottom>
                                                        <span style={{ fontWeight: 'bold' }}>Choose a file</span> or drag it here
                                                    </Typography>
                                                </div>
                                                <Typography variant="subtitile">Auto-fill the Rate confirmation and one-click upload</Typography>
                                            </Box>
                                        </label>

                                        <div style={{ display: 'flex' }}>
                                            {selectedFiles.map((file, index) => (
                                                <Box key={index} sx={{ margin: '10px' }}>
                                                    <Typography variant="body1" gutterBottom color={'green'}>
                                                        {file.name}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </div>
                                    </>
                                </Grid>
                                <Grid item lg={2} alignItems={'center'} display={'flex'}>
                                    <Button
                                        variant="contained"
                                        onClick={fileUploadHandler}
                                        disabled={loadingFile || values.custName === '' || selectedFiles?.length === 0}
                                        color="orange"
                                        endIcon={<Send />}
                                        sx={{ color: 'white' }}
                                    >
                                        Generate
                                    </Button>
                                </Grid>
                            </Grid>
                            {loadingFile && <LinearProgress style={{ marginTop: '10px', width: '100%', backgroundColor: 'orange' }} />}
                        </SubCard>

                        <SubCard
                            sx={{
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '500',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend',
                                            lineHeight: '30px'
                                        }}
                                    >
                                        Assign To Driver
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {driverLoading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                className="text-input"
                                                id="driver"
                                                options={driverOptions}
                                                loading={driverLoading}
                                                open={driverOpen}
                                                onBlur={formik.handleBlur('driver')}
                                                onOpen={() => {
                                                    setDriverOpen(true);
                                                }}
                                                onClose={() => {
                                                    setDriverOpen(false);
                                                }}
                                                getOptionLabel={(option) => (option.name ? option.name : '')}
                                                onInputChange={(_event, newInputValue) => {
                                                    searchByDriverName(newInputValue);
                                                }}
                                                onChange={(_event, newValue) => {
                                                    setFieldValue('driver', newValue);
                                                    if (newValue) {
                                                        setFieldValue('truck', newValue?.truck_info?.truck);
                                                    } else {
                                                        setFieldValue('truck', '');
                                                    }
                                                }}
                                                value={values.driver}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Driver Name"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {driverLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            )
                                                        }}
                                                        error={Boolean(touched.driver && errors.driver)}
                                                    />
                                                )}
                                            />
                                            <FormHelperText error={Boolean(touched.driver && errors.driver)}>
                                                {touched.driver && errors.driver ? errors.driver : 'Type atleast 2 characters to populate the list'}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                </Grid>
                                {/* truck no */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TextField
                                        className="text-input"
                                        fullWidth
                                        type="text"
                                        label="Truck Number "
                                        color="orange"
                                        value={values.truck}
                                        inputProps={{ readOnly: true }}
                                    />
                                </Grid>
                            </Grid>
                        </SubCard>

                        <SubCard
                            sx={{
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <Grid container spacing={3}>
                                {/* General Info */}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            width: '375px',
                                            height: '40px',
                                            fontWeight: '500',
                                            fontSize: '24px',
                                            fontFamily: 'Lexend',
                                            lineHeight: '30px'
                                        }}
                                    >
                                        General Information
                                    </Typography>
                                </Grid>
                                {/* customer */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TextField
                                        className="text-input"
                                        fullWidth
                                        type="text"
                                        label="Customer Name"
                                        color="orange"
                                        value={values.custName}
                                        inputProps={{ readOnly: true }}
                                    />
                                </Grid>
                                {/* Weight */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Weight"
                                        {...getFieldProps('weight')}
                                        error={Boolean((touched.weight && errors.weight) || (addError && addError.weight))}
                                        helperText={(touched.weight && errors.weight) || (addError && addError.weight)}
                                    />
                                </Grid>
                                {/* revenue */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Revenue"
                                        {...getFieldProps('revenue')}
                                        error={Boolean((touched.revenue && errors.revenue) || (addError && addError.revenue))}
                                        helperText={(touched.revenue && errors.revenue) || (addError && addError.revenue)}
                                    />
                                </Grid>
                                {/* Load */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Load"
                                        {...getFieldProps('load')}
                                        error={Boolean((touched.load && errors.load) || (addError && addError.load))}
                                        helperText={(touched.load && errors.load) || (addError && addError.load)}
                                    />
                                </Grid>
                                {/* millage */}
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        className="text-input"
                                        color="orange"
                                        type="text"
                                        label="Millage"
                                        {...getFieldProps('millage')}
                                        error={Boolean((touched.millage && errors.millage) || (addError && addError.millage))}
                                        helperText={(touched.millage && errors.millage) || (addError && addError.millage)}
                                    />
                                </Grid>
                                {/* Pick Up */}
                            </Grid>
                        </SubCard>

                        <SubCard
                            sx={{
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            <FieldArray name="pickup">
                                {({ push, remove }) => {
                                    return (
                                        <>
                                            {values.pickup.map((i, index) => {
                                                const pickupErrors = (errors.pickup?.length && errors.pickup?.[index]) || {};
                                                const pickupTouched = (touched.pickup?.length && touched.pickup?.[index]) || {};
                                                return (
                                                    <div key={`pickup-${index}`}>
                                                        <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    borderRadius: '50%',
                                                                    backgroundColor: theme.palette.orange.main,
                                                                    color: '#ffffff',
                                                                    px: '10px',
                                                                    py: '6px'
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Typography>

                                                            <Typography
                                                                sx={{
                                                                    ml: 1,
                                                                    mt: 0.8,

                                                                    width: '375px',
                                                                    height: '40px',
                                                                    fontWeight: '500',
                                                                    fontSize: '24px',
                                                                    fontFamily: 'Lexend'
                                                                }}
                                                            >
                                                                PICKUP
                                                            </Typography>
                                                        </Stack>
                                                        <Grid container spacing={3}>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                {loadingFile ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="Address"
                                                                        value={values.pickup[index].street}
                                                                        onChange={(e) => setFieldValue(`pickup[${index}].street`, e.target.value)}
                                                                        error={Boolean(
                                                                            (pickupTouched.street && pickupErrors.street) ||
                                                                                (addError && addError.pickups && addError.pickups[index].street)
                                                                        )}
                                                                        helperText={
                                                                            (pickupTouched.street && pickupErrors.street) ||
                                                                            (addError && addError.pickups && addError.pickups[index].street)
                                                                        }
                                                                    />
                                                                )}
                                                            </Grid>
                                                            {/* State */}
                                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                {loadingFile || stateLoading ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        select
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="State"
                                                                        value={values.pickup[index].state}
                                                                        onChange={(e) =>
                                                                            getCityList(`pickup[${index}].state`, `pickup[${index}].city_list`, e.target.value)
                                                                        }
                                                                        error={Boolean(pickupTouched.state && pickupErrors.state)}
                                                                        helperText={pickupTouched.state && pickupErrors.state}
                                                                    >
                                                                        <MenuItem value="">Select State</MenuItem>
                                                                        {stateList &&
                                                                            stateList.map((state, i) => (
                                                                                <MenuItem key={i} value={state.id}>
                                                                                    {state.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                )}
                                                            </Grid>
                                                            {/* City */}
                                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                {loadingFile || loadingCity ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        select
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="City"
                                                                        value={values.pickup[index].city}
                                                                        onChange={(e) => setFieldValue(`pickup[${index}].city`, e.target.value)}
                                                                        error={Boolean(pickupTouched.city && pickupErrors.city)}
                                                                        helperText={pickupTouched.city && pickupErrors.city}
                                                                    >
                                                                        <MenuItem value="">Select City</MenuItem>
                                                                        {values.pickup[index].city_list &&
                                                                            values.pickup[index].city_list.map((city, i) => (
                                                                                <MenuItem key={i} value={city.id}>
                                                                                    {city.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>
                                                                )}
                                                            </Grid>

                                                            {/* Zip */}
                                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                {loadingFile ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="Zip"
                                                                        value={values.pickup[index].zipcode}
                                                                        onChange={(e) => setFieldValue(`pickup[${index}].zipcode`, e.target.value)}
                                                                        onInput={NumberOnlyHandler}
                                                                        error={Boolean(pickupTouched.zipcode && pickupErrors.zipcode)}
                                                                        helperText={pickupTouched.zipcode && pickupErrors.zipcode}
                                                                        inputProps={{
                                                                            maxLength: 5
                                                                        }}
                                                                    />
                                                                )}
                                                            </Grid>
                                                            {/* Date */}
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                {loadingFile ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                        <DesktopDatePicker
                                                                            className="text-input"
                                                                            label="Pickup Date"
                                                                            openTo="day"
                                                                            views={['year', 'month', 'day']}
                                                                            value={values.pickup[index].date}
                                                                            onChange={(newValue) => {
                                                                                setFieldValue(`pickup[${index}].date`, newValue);
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    color="orange"
                                                                                    fullWidth
                                                                                    {...params}
                                                                                    name="date"
                                                                                    onBlur={formik.handleBlur}
                                                                                    error={Boolean(pickupTouched.date && pickupErrors.date)}
                                                                                    helperText={pickupTouched.date && pickupErrors.date}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                )}
                                                            </Grid>
                                                            {/* Time */}
                                                            <Grid item lg={3} md={3} sm={3} xs={12}>
                                                                {loadingFile ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                        <TimePicker
                                                                            className="text-input"
                                                                            color="orange"
                                                                            label={'Pickup Time'}
                                                                            value={values.pickup[index].time}
                                                                            onChange={(newValue) => setFieldValue(`pickup[${index}].time`, newValue)}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    color="orange"
                                                                                    fullWidth
                                                                                    {...params}
                                                                                    name="time"
                                                                                    onBlur={formik.handleBlur}
                                                                                    error={Boolean(pickupTouched.time && pickupErrors.time)}
                                                                                    helperText={pickupTouched.time && pickupErrors.time}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                )}
                                                            </Grid>
                                                            {/* Company Name*/}
                                                            <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mb: 2 }}>
                                                                {loadingFile ? (
                                                                    <Skeleton height="50px" />
                                                                ) : (
                                                                    <TextField
                                                                        fullWidth
                                                                        className="text-input"
                                                                        color="orange"
                                                                        type="text"
                                                                        label="Company Name"
                                                                        value={values.pickup[index].customer}
                                                                        onChange={(e) => setFieldValue(`pickup[${index}].customer`, e.target.value)}
                                                                    />
                                                                )}
                                                            </Grid>
                                                            {values.pickup?.length > 1 && (
                                                                <IconButton
                                                                    disabled={isSubmitting && Boolean(errors.pickup)}
                                                                    onClick={() => remove(index)}
                                                                    size="small"
                                                                    color="error"
                                                                >
                                                                    <DeleteForeverIcon fontSize="large" />
                                                                </IconButton>
                                                            )}
                                                        </Grid>
                                                    </div>
                                                );
                                            })}
                                            <Button
                                                variant="contained"
                                                color="orange"
                                                onClick={() => push(empPic)}
                                                sx={{
                                                    textTransform: 'uppercase',
                                                    color: 'white',
                                                    fontFamily: 'Lexend',
                                                    fontWeight: 500,
                                                    lineHeight: '20px',

                                                    fontStyle: 'normal',

                                                    height: '36px',
                                                    fontSize: '16px'
                                                }}
                                                startIcon={<AddIcon />}
                                            >
                                                Add PickUp
                                            </Button>
                                        </>
                                    );
                                }}
                            </FieldArray>
                        </SubCard>

                        <SubCard
                            sx={{
                                borderRadius: '8px',
                                mb: 2,
                                boxShadow: '0px 0px 13px rgba(20, 41, 83, 0.17)'
                            }}
                        >
                            {/* <Grid container spacing={2}> */}
                            <FieldArray name="delivery">
                                {({ push, remove }) => (
                                    <>
                                        {values.delivery.map((i, index) => {
                                            const deliveryErrors = (errors.delivery?.length && errors.delivery?.[index]) || {};
                                            const deliveryTouched = (touched.delivery?.length && touched.delivery?.[index]) || {};
                                            return (
                                                <div key={`delivery-${index}`}>
                                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ mb: 2 }}>
                                                        <Typography
                                                            variant="h4"
                                                            sx={{
                                                                borderRadius: '50%',
                                                                backgroundColor: theme.palette.orange.main,
                                                                color: '#ffffff',
                                                                px: '10px',
                                                                py: '7px'
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </Typography>
                                                        <Typography
                                                            variant="h2"
                                                            sx={{
                                                                ml: 1,
                                                                mt: 0.8,
                                                                width: '375px',
                                                                height: '40px',
                                                                fontWeight: '500',
                                                                fontSize: '24px',
                                                                fontFamily: 'Lexend'
                                                            }}
                                                        >
                                                            DELIVERY
                                                        </Typography>
                                                    </Stack>
                                                    <Grid container spacing={3}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            {loadingFile ? (
                                                                <Skeleton height="50px" />
                                                            ) : (
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Address"
                                                                    value={values.delivery[index].street}
                                                                    onChange={(e) => setFieldValue(`delivery[${index}].street`, e.target.value)}
                                                                    error={
                                                                        Boolean(deliveryTouched.street && deliveryErrors.street) ||
                                                                        (addError && addError.delivery?.length > 0 && addError.delivery?.[index].street)
                                                                    }
                                                                    helperText={
                                                                        (deliveryTouched.street && deliveryErrors.street) ||
                                                                        (addError &&
                                                                            addError.delivery &&
                                                                            addError.delivery?.length > 0 &&
                                                                            addError.delivery?.[index].street)
                                                                    }
                                                                />
                                                            )}
                                                        </Grid>
                                                        {/* State */}
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            {loadingFile ? (
                                                                <Skeleton height="50px" />
                                                            ) : (
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="State"
                                                                    value={values.delivery[index].state}
                                                                    onChange={(e) =>
                                                                        getCityList(`delivery[${index}].state`, `delivery[${index}].city_list`, e.target.value)
                                                                    }
                                                                    error={Boolean(deliveryTouched.state && deliveryErrors.state)}
                                                                    helperText={deliveryTouched.state && deliveryErrors.state}
                                                                >
                                                                    <MenuItem value="">Select State</MenuItem>
                                                                    {stateList &&
                                                                        stateList.map((state, i) => (
                                                                            <MenuItem key={i} value={state.id}>
                                                                                {state.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                </TextField>
                                                            )}
                                                        </Grid>
                                                        {/* City */}
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            {loadingFile || loadingCity ? (
                                                                <Skeleton height="50px" />
                                                            ) : (
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="City"
                                                                    value={values.delivery[index].city}
                                                                    onChange={(e) => setFieldValue(`delivery[${index}].city`, e.target.value)}
                                                                    error={Boolean(deliveryTouched.city && deliveryErrors.city)}
                                                                    helperText={deliveryTouched.city && deliveryErrors.city}
                                                                >
                                                                    <MenuItem value="">Select City</MenuItem>
                                                                    {values.delivery[index].city_list &&
                                                                        values.delivery[index].city_list.map((city, i) => (
                                                                            <MenuItem key={i} value={city.id}>
                                                                                {city.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                </TextField>
                                                            )}
                                                        </Grid>

                                                        {/* Zip */}
                                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                                            {loadingFile ? (
                                                                <Skeleton height="50px" />
                                                            ) : (
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Zip"
                                                                    value={values.delivery[index].zipcode}
                                                                    onChange={(e) => setFieldValue(`delivery[${index}].zipcode`, e.target.value)}
                                                                    onInput={NumberOnlyHandler}
                                                                    error={Boolean(deliveryTouched.zipcode && deliveryErrors.zipcode)}
                                                                    helperText={deliveryTouched.zipcode && deliveryErrors.zipcode}
                                                                    inputProps={{
                                                                        maxLength: 5
                                                                    }}
                                                                />
                                                            )}
                                                        </Grid>
                                                        {/* Date */}
                                                        <Grid item lg={3} md={3} sm={3} xs={12}>
                                                            {loadingFile ? (
                                                                <Skeleton height="50px" />
                                                            ) : (
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DesktopDatePicker
                                                                        className="text-input"
                                                                        label="Delivery Date"
                                                                        openTo="day"
                                                                        views={['year', 'month', 'day']}
                                                                        value={values.delivery[index].date}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue(`delivery[${index}].date`, newValue);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                color="orange"
                                                                                fullWidth
                                                                                {...params}
                                                                                error={
                                                                                    Boolean(deliveryTouched.date && deliveryErrors.date) ||
                                                                                    (addError &&
                                                                                        addError.delivery &&
                                                                                        addError.delivery[index] &&
                                                                                        addError.delivery[index].date)
                                                                                }
                                                                                helperText={
                                                                                    (deliveryTouched.date && deliveryErrors.date) ||
                                                                                    (addError &&
                                                                                        addError.delivery &&
                                                                                        addError.delivery[index] &&
                                                                                        addError.delivery[index].date)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            )}
                                                        </Grid>
                                                        {/* Time */}
                                                        <Grid item lg={3} md={3} sm={3} xs={12}>
                                                            {loadingFile ? (
                                                                <Skeleton height="50px" />
                                                            ) : (
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <TimePicker
                                                                        className="text-input"
                                                                        color="orange"
                                                                        label={'Delivery Time'}
                                                                        value={values.delivery[index].time}
                                                                        onChange={(newValue) => setFieldValue(`delivery[${index}].time`, newValue)}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                color="orange"
                                                                                fullWidth
                                                                                {...params}
                                                                                error={
                                                                                    Boolean(deliveryTouched.time && deliveryErrors.time) ||
                                                                                    (addError &&
                                                                                        addError.delivery &&
                                                                                        addError.delivery[index] &&
                                                                                        addError.delivery[index].date)
                                                                                }
                                                                                helperText={
                                                                                    (deliveryTouched.time && deliveryErrors.time) ||
                                                                                    (addError &&
                                                                                        addError.delivery &&
                                                                                        addError.delivery[index] &&
                                                                                        addError.delivery[index].date)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            )}
                                                        </Grid>
                                                        {/* Company Name*/}
                                                        <Grid item lg={6} md={6} sm={6} xs={12} sx={{ mb: 2 }}>
                                                            {loadingFile ? (
                                                                <Skeleton height="50px" />
                                                            ) : (
                                                                <TextField
                                                                    fullWidth
                                                                    className="text-input"
                                                                    color="orange"
                                                                    type="text"
                                                                    label="Company Name"
                                                                    value={values.delivery[index].customer}
                                                                    onChange={(e) => setFieldValue(`delivery[${index}].customer`, e.target.value)}
                                                                />
                                                            )}
                                                        </Grid>
                                                        {values.delivery?.length > 1 && (
                                                            <IconButton
                                                                disabled={isSubmitting && Boolean(errors.delivery)}
                                                                onClick={() => remove(index)}
                                                                size="small"
                                                                color="error"
                                                            >
                                                                <DeleteForeverIcon fontSize="large" />
                                                            </IconButton>
                                                        )}
                                                    </Grid>
                                                </div>
                                            );
                                        })}
                                        <Grid item xs={6}>
                                            <Button
                                                disabled={isSubmitting}
                                                variant="contained"
                                                color="orange"
                                                onClick={() => push(empDel)}
                                                size="medium"
                                                sx={{
                                                    textTransform: 'uppercase',

                                                    color: 'white',
                                                    fontFamily: 'Lexend',
                                                    fontWeight: 500,

                                                    lineHeight: '20px',

                                                    fontStyle: 'normal',

                                                    fontSize: '16px',

                                                    height: '36px',
                                                    fontSize: '16px'
                                                }}
                                                startIcon={<AddIcon />}
                                            >
                                                Add Delivery
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </FieldArray>
                        </SubCard>
                    </SubCard>
                </Form>
            </FormikProvider>
        </>
    );
};

export default AddloadForm;
